import React, { useEffect, useState } from 'react';
import { Button, Tabs } from 'antd';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getFirestore, collection, getDocs } from 'firebase/firestore';

import OverallSentiment from '../../../../Common/Analytics/OverallSentiment';
import ContentCard from '../../../../Common/ContentCard';
import ScriptTab from './ScriptTab';
import { ICharacter, ICharactersStats } from '../../../../../types/entries';
import { RootState } from '../../../../../store/reducers';
import { moduleName as charactersModuleName } from '../../../../../store/ducks/characters';
import LatestRecordings from '../../../../Common/LatestRecordings';

import styles from '../index.module.less';
import PageWrapper from '../../../../Common/PageWrapper';
import { getFirebaseApp } from '../../../../../App';

declare global {
  interface Window {
    getNeuralVoicePopupSettings: () => void;
  }
}

interface ICharacterDetails {
  loading: boolean;
  character: ICharacter | null;
  stats: ICharactersStats | null;
}

const CharacterDetails: React.FC<ICharacterDetails> = ({ loading, character, stats }) => {
  const { characterId } = useParams();
  const navigate = useNavigate();
  const [characterActivationId, setCharacterActivationId] = useState<string>('');
  const firebaseApp = getFirebaseApp();
  const db = getFirestore(firebaseApp);

  useEffect(() => {
    if (!characterActivationId) return;

    if (typeof window.getNeuralVoicePopupSettings === 'function') {
      window.getNeuralVoicePopupSettings();
    }
  }, [characterActivationId]);

  useEffect(() => {
    getCharacterActivationId();
  }, [characterId]);

  const getCharacterActivationId = async () => {
    const querySnapshot = await getDocs(collection(db, 'site-characters'));

    querySnapshot.forEach((doc) => {
      Object.values(doc.data()).forEach((characterDoc) => {
        if (characterDoc.domain && typeof characterDoc.domain === 'string') {
          const currentOrigin = characterDoc.domain.endsWith('/')
            ? `${window.location.origin}/`
            : window.location.origin;

          if (characterDoc.voice_id === characterId && currentOrigin === characterDoc.domain) {
            setCharacterActivationId(characterDoc.activation_id);
          }
        }
      });
    });
  };

  useEffect(() => {
    if (loading || character || character === null) return;

    navigate('/not-found', { replace: true });
  }, [loading, character]);

  const tabs = [
    {
      label: 'Embed Script',
      component: <ScriptTab character={character} />,
    },
    {
      label: 'Sentiment',
      component: <OverallSentiment sentiment={stats?.sentimental} width={700} height={500} radius={200} />,
    },
    {
      label: 'Other Settings',
      component: <ScriptTab character={character} />,
    },
  ];

  return (
    <PageWrapper
      characterId={characterId}
      loading={loading}
      extraColumn={{
        width: 180,
        align: 'center',
        renderText: () => (characterActivationId ? <Button id={characterActivationId}>Try Now</Button> : <div />),
      }}
    >
      {/* <Button className={styles.tryButton}> */}
      {/*  <span className="icon-bubbles" /> */}
      {/* </Button> */}

      <LatestRecordings title="Recent Calls" characterId={characterId} />
      <ContentCard width="100%">
        <div className="w-full">
          <Tabs
            defaultActiveKey="1"
            type="card"
            style={{ marginBottom: 32 }}
            items={tabs.map((tab) => ({
              label: tab.label,
              key: tab.label,
              children: tab.component,
            }))}
          />
        </div>
      </ContentCard>
    </PageWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[charactersModuleName].loading,
  character: state[charactersModuleName].character,
  stats: state[charactersModuleName].stats,
});

export default connect(mapStateToProps)(CharacterDetails);
